<template>
    <div class="mu">
        <h1 style="color: gray">{{ webinfo['admin_name'] }}</h1>
        <el-row :gutter="40">
          <el-col :span="12"><div class="grid-content ep-bg-purple" />
            <div class="demo-progress"> 
            <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.hy" >
              <el-button text>管理员:{{ tableData.hy }}个</el-button>
            </el-progress>
            <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.xmhx" status="exception" >
              <el-button text>后台版块:{{ tableData.xmhx }}个</el-button>
            </el-progress>
            <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.xw" status="success" >
              <el-button text>内容管理:{{ tableData.xw }}条</el-button>
            </el-progress>
          </div>
          </el-col>
          <el-col :span="12"><div class="grid-content ep-bg-purple-light" />
            <div class="demo-progress" >  
              <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.fl" status="warning" >
                <el-button text>前台分类:{{ tableData.fl }}个</el-button>
              </el-progress>
              <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.feed" color="#DDE192">
                <el-button text>信息反馈:{{ tableData.feed }}条</el-button>
              </el-progress>
              <el-progress :text-inside="true" :stroke-width="26" :percentage="tableData.ads" color="#E3A7DE">
                <el-button text>广告管理:{{ tableData.ads }}条</el-button>
              </el-progress>
            </div>
          </el-col>
        </el-row>
        
    </div>
    
  </template>
  
  <script>
  import { toRefs, reactive } from "vue";
  import { Homeres } from "@/network/index";
  import { Getconflists } from "@/network/login";
  export default {
    setup() {
      const format = (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`)
      const state = reactive({
            tableData:[],
            webinfo : [],

        });
        Homeres().then((e)=>{
            state.tableData = e;
        });
        Getconflists().then( (e)=>{
            state.webinfo = e;
        } )
        return {
            ...toRefs(state),
            format
        };
    }

  }

  </script>
  
  <style scoped>
  .demo-progress .el-progress--line {
    margin-bottom: 15px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

  }
  .mu{
    text-align: center;

  }
  .el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
  </style>
