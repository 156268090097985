<template>
 
    <audio id="audio" ref="audio" controls hidden="true" :src="audioURL"/>
    <el-container class="layout" style="height: 100%">
        <aside style="height: 100%">
            <el-menu default-active="-1" class="el-menu-vertical-demo" :collapse="isCollapse" active-text-color="#ffd04b" background-color="#545c64" text-color="#fff">
                <div class="logo">
                    <router-link to="/index/home" class="router">
                        <!-- <img src="@/assets/logo.jpg" alt="logo" /> -->
                        <h1>Admin</h1>
                    </router-link>
                </div>
                <router-link to="/index/home" @click.native="refreshPage('/index/home')" @click="addTab(editableTabsValue,'首页','/index/home')" >
                    <el-menu-item index="-1">
                        <el-icon><House /></el-icon>
                        <template #title>首页面板</template>
                    </el-menu-item>
                </router-link>
                <!-- <router-link to="/index/userinfo">
                    <el-menu-item index="-2">
                        <el-icon><User /></el-icon>
                        <template #title>个人中心</template>
                    </el-menu-item>
                </router-link> -->
                <template v-for="(item,index,key) in menu" :key="key">
                    <a :href="item.src" target="_blank" v-if="item.type == 2">
                        <el-menu-item :index="item.mid">
                            <el-icon>
                                <Icon :icon="item.icon_class" />
                            </el-icon>
                            <template #title>{{item.label}}</template>
                        </el-menu-item>
                    </a>
                    <router-link  v-else-if="item.type == 1" :to="item.src"  @click="addTab(editableTabsValue,item.label,item.src)">
                        <el-menu-item :index="item.mid">
                            <el-icon>
                                <Icon :icon="item.icon_class" />
                            </el-icon>
                            <template #title>{{item.label}}</template>
                        </el-menu-item>
                    </router-link>
                    <el-sub-menu v-else :index="item.mid">
                        <template #title>
                            <el-icon>
                                <Icon :icon="item.icon_class" />
                            </el-icon>
                            <span>{{item.label}}</span>
                        </template>
                        <template v-for="(items,indexs,keys) in item.children" :key="keys" >
                            <router-link :to="items.src" v-if="items.type == 1" @click.native="refreshPage(items.src)" @click="addTab(editableTabsValue,items.label,items.src)">
                                <el-menu-item :index="items.mid">
                                    <el-icon>
                                        <Icon :icon="items.icon_class" />
                                    </el-icon>
                                    <span>{{items.label}}</span>
                                </el-menu-item>
                            </router-link>
                            <a :href="items.src" target="_blank" v-if="items.type == 2">
                                <el-menu-item :index="items.mid">
                                    <el-icon>
                                        <Icon :icon="items.icon_class" />
                                    </el-icon>
                                    <span>{{items.label}}</span>
                                </el-menu-item>
                            </a>
                        </template>
                    </el-sub-menu>
                </template>
            </el-menu>
            <div class="flexible" @click="isCollapse = !isCollapse">
                <el-icon v-if="isCollapse" color="white" :size="40"><ArrowRight /></el-icon>
                <el-icon v-else color="white" :size="40"><ArrowLeft /></el-icon>
            </div>
        </aside>
        <el-container>

                <el-header  class="righttop" style="text-align: right; font-size: 20px;">
                    <div class="cutop" v-if="isMobile">
                      
                        <el-breadcrumb separator="">
                            <el-breadcrumb-item :to="{ path: '/' }">首页 </el-breadcrumb-item>
                            <el-breadcrumb-item v-for="(item, index) in menu" >
                            <div v-for="items in item.children">
                                    <span v-if="items.src == currentPath">  
                                        <span v-if="item.mid == items.parent_id">{{ item.label }} /</span> 
                                        {{ items.label }}</span>
                            </div>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                        
                    </div>
                    <div class="toolbar" >
                        <el-badge :value="msg" :max="99" class="item" style="margin-right: 20px;margin-top: -5px;">
                            <el-button text="plain" @click.native="tomes('/index/feedback')" @click="addTab(editableTabsValue,'信息反馈','/index/feedback')">未读消息</el-button>
                        </el-badge>
                        <div style="font-size: 20px">
                            <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="刷新页面"
                                placement="top"
                            >
                            <Refresh style="width: 1em; height: 1em; margin-right: 10px" @click="sxpage"/>
                            </el-tooltip>
                            
                        </div>
                        <el-dropdown size="large" type="primary">
                            
                            <span>{{user.name}}<el-icon style="margin-left: 10px; margin-top: 1px"><ArrowDown /></el-icon></span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <!-- <el-dropdown-item >个人中心</el-dropdown-item> -->
                                    <el-dropdown-item @click="tologout">退出</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    

                </el-header>
                <!-- tabs菜单开始 -->
                
                <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="tabClick">
                <el-tab-pane style="margin: 10px;"
                    v-for="(item, index) in editableTabs"
                    :key="item.name"
                    :label="item.title"
                    :name="item.name"
                    
                >
                    <!-- {{item.content}} -->
                    
                    
                </el-tab-pane>
                </el-tabs>

                <!-- tabs菜单结束 -->
                
                <el-main style="margin-top: -25px;">
                        <router-view></router-view>
                </el-main>

            
            
            
            
        </el-container>
    </el-container>
</template>
<script>
import { reactive, toRefs, toRaw, onMounted } from "vue";
import { Index, Message } from "@/network/index";
import Icon from "@/components/Icon.vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";


export default {
    name: "Index",
    components: {
        Icon
    },
    data() {
      return {
        editableTabsValue: '1',
        editableTabs: [],
        tabIndex: 2,
        isMobile: false, // 默认不隐藏

      }
    },
    mounted() {
        this.checkDeviceType(); // 页面加载完成后调用函数检查设备类型
        this.$refs.audio.src = require("@/assets/y1897.wav");
    },
    beforeCreate(){  
        setTimeout(() => {
            Index().then( (e)=>{
            let str = this.$router.currentRoute.value.meta.title;
            // let arr = str.split("-"); //以-分割数据，取后面的值
            // console.log(arr[1]);
            this.editableTabs.push({
                title: str,
                name: '1',
                content: this.$router.currentRoute.value.path,
            });

        })
        
        }, 500);
        
        

    },
    

    methods: {
        //查是否手机登陆
        checkDeviceType() {
            const userAgentInfo = navigator.userAgent;
            if (/(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(userAgentInfo)) {
                
                this.isMobile = false; // 否则显示该div\
                this.isCollapse = true;
            } else {
                this.isMobile = true; // 若是移动设备则隐藏该div
            }
        },


        refreshPage(url) {
            this.currentPath = url;
        },
        
      addTab(targetName,name,url) {
        let tabs = this.editableTabs;
        let abc = toRaw(tabs.filter(tab => tab.content == url));
        // console.log('123',abc[0],url)

            
        if(!abc[0]){
            let newTabName = ++this.tabIndex + '';
            this.editableTabs.push({
                title: name,
                name: newTabName,
                content: url,
            });
            this.editableTabsValue = newTabName;
        } else{
            this.editableTabsValue = abc[0]['name']
        }
        
    
       
            
        
      },
      tabClick(){
            setTimeout(() => {
                let tabs = this.editableTabs;
                let activeName = this.editableTabsValue;
                let abc = toRaw(tabs.filter(tab => tab.name == activeName));
                
                this.$router.push(abc[0]['content']);
                this.currentPath = abc[0]['content'];
            }, 500);
            
            
   
     },
      removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        let abc = tabs.filter(tab => tab.name == activeName);
        // console.log('123',abc[0]['content'])
        this.$router.push(abc[0]['content']);
    }
    },
    setup() {

        const router = useRouter();
        const token = window.localStorage.getItem("token");
        if (!token) {
            ElMessage.error('请先登录');
            setTimeout(() => {
                router.push({ path: "/login" });
            }, 1500);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
        const currentPath = router.currentRoute.value.path; // 获取当前路径this.$

        
        const user = JSON.parse(window.localStorage.getItem("user"));
        
// console.log(path.value); // 输出当前路径
        const state = reactive({
            isCollapse: false,
            user: user,
            menu : [],
            currentPath:currentPath,
            msg:'',
        });

        Index().then( (e)=>{
            state.menu = e.menu;
        }).catch(() => {
                window.localStorage.setItem("token", "");
                ElMessage({
                    type: "error",
                    message: "token不存在"
                });
                setTimeout(() => {
                    router.push({ path: "/login" });
                }, 3000);
        });
        Message().then( (e)=>{
            state.msg = e;
        });
       const mm = setInterval(() => {
            Message().then((e)=>{                       
                if(e < state.msg ){
                    state.msg = e;   
                } else if(e > state.msg){
                    state.msg = e; 
                    aplayAudio();
                }
            })
        }, 30000); // 每隔10秒发送一次请求  

            
            // 语音播放
            const aplayAudio = () => {
                const audio = document.getElementById('audio')
                audio.play(); 
            };
        // 退出登录
        const tologout = () => {
            window.localStorage.setItem("token", "");
            ElMessage({ message: "退出成功", type: "success" });
            setTimeout(() => {
                router.push({ path: "/login" });
                clearInterval(mm);
            }, 1000);
            
        };
        const tomes = () => {
            setTimeout(() => {
                router.push({ path: "/index/feedback" });
            }, 500);
            
        };
        const sxpage = () => {
            window.location.reload(); // 刷新页面
            ElMessage({ message: "刷新成功", type: "success" });
        };
        return {
            ...toRefs(state),
            tologout,
            sxpage,
            aplayAudio,
            tomes
        };
    }
};
</script>
<style>

a {
    text-decoration: none;
}
.righttop{
    display: flex;
    width: 100%;
}
.cutop {
    width: 93%;
    /* background-color: white; */
    text-align: left; 
    font-size: 18px;
    padding: 10px;
    margin-top: 10px;
    /* margin-top: -40px;
    z-index: 9999999; */
}
.layout {
    background-color: #f0f2f5;
}
.layout .el-header {
    position: relative;
    background-color: white;
    color: var(--el-text-color-primary);
}
.layout aside {
    color: var(--el-text-color-primary);
    background: #001529;
}
.layout .el-menu {
    border-right: none;
}
.layout .el-main {
    margin: 0px 10px;
    background-color: white;
}
.layout .toolbar {
    width: 300px;
    /* display: inline-flex;
    align-items: center;
    justify-content: center; */
    /* height: 100%; */
    right: 5px;
    margin-top: 20px;
    display: flex;
	justify-content: right;
}
aside {
    position: relative;
}
.flexible {
    background-color: #002140;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
.layout aside .logo .router {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation-duration: 0.1s;
    overflow: hidden;
    text-decoration: none;
    padding-left: 1px;
    position: relative;
    /* left: 2px; */
}
.layout aside .logo .router h1 {
    /* margin-left: 10px; */
    color: aliceblue;
    overflow: hidden;
}
.layout aside .logo .router img {
    padding-left: 5px;
    width: 48px;
    height: 48px;
}

</style>