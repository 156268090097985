<template>
    <div class="ys">
        <el-tabs v-model="activeName" class="demo-tabs" type="card"  @tab-click="handleClick">
            <el-tab-pane label="后台配置" name="1"></el-tab-pane>
            <el-tab-pane label="前台配置" name="2"></el-tab-pane>
        </el-tabs>
        <el-form label-width="80px" size="large">
       
        <div   v-for="(item,index) in tableData" :key="index" >
            <el-form-item :label="item.config_info">
            <el-col :span="16">
                <el-input v-if="item.config_type == 1" v-model="item.config_value"  :placeholder="item.config_desc" />
                <el-input
                    v-if="item.config_type == 3"
                    v-model="item.config_value"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    :placeholder="item.config_desc"
                />
            </el-col>
            
            <div v-if="item.config_type == 2" style="width: 66.7%;">
                <!-- <el-input v-model="item.config_value"  :placeholder="item.config_desc" id="myInput" :name="index" /> -->
                <el-upload class="avatar-uploader"  :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" @click="tpid(index)">
                    <img v-if="item.config_value" :src="base + item.config_value"  class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </div>
            <div style="margin-top: 2px" v-if="item.config_type == 4">
                <el-radio-group v-model="item.config_value" size="small" >
                    <div v-for="(its,index) in arr" :key="index">
                        
                        <el-radio :label="''+index+''" border style="margin-right: 5px;"> {{its}} </el-radio>
                    
                    </div>
                
                </el-radio-group>
            </div>
            </el-form-item>
            
        </div>
        
        

        <el-form-item>
        <el-button type="primary" @click="onSubmit">修改</el-button>
        </el-form-item>
        </el-form>
    </div>
    
</template>
<script>
import { toRefs, reactive } from "vue";
import { Configvalue, Cvlist } from "@/network/config";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
    name: "Confs",
    setup() {
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL

        const state = reactive({
            activeName:"1",
            arr:[],
            tpi:"",
            pics:"",
            tableData:[],
            upload_img: base + "/admin/Index/upload_img",
            token: window.localStorage.getItem("token"),


        });
        Cvlist().then((e)=>{
            state.tableData = e;
            state.tableData.forEach((item,index) => {

                if(item.config_type == 4){  
                    state.arr = item.config_desc.split(',');
                }
                
                
            });
            
        });
        const router = useRouter();
        const tpid =(index)=>{
            console.log(index,"index")
            state.tpi = index
        }
        const onSubmit = () => {
            // const values = state.tableData.map(item=> item.config_name+':'+item.config_value); 
            //将tableData数组转化成对象obj
            const obj = {};
            state.tableData.forEach((item,index) => {
                obj[item.config_name] = item.config_value;
            });
           
            //取出图片所在id
            const inputValue = state.tpi

            if(state.pics){
                 // 获取原始对象的所有属性名称
                const keys = Object.keys(obj);
                console.log(keys[inputValue],'keys');
                for (let i = 0; i < keys.length - 1; i++) {
                    const key = keys[i];
                    obj[key] = obj[key]; // 复制除了最后一个键之外的其他键及其相应的值到新对象
                }
                
                obj[keys[inputValue]] = state.pics; // 设置同字段新值
                
            }
           
            
            // console.log(obj); // { a: "a", b: "b" }
            

            Configvalue({obj:obj,type:state.activeName}).then((res) => {
                // console.log(res);
                if (res == 0) {
                    ElMessage({ message: "修改成功", type: "success" });
                    Cvlist({type:state.activeName}).then((e)=>{
                        state.tableData = e;
                    } )
                }else{
                    ElMessage({ message: "修改失败", type: "error" });
                }
            });
        };
        // 管理员token，上传图片接口需要
        const token = window.localStorage.getItem("token");
        //单图片上传
        const beforeAvatarUpload = (file) => {
            const isJPG = file.type === "image/png" || "image/jpg" || "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                ElMessage({ message: "上传头像图片只能是 JPG/PNG/JPEG 格式!", type: "error" });
            }
            if (!isLt2M) {
                ElMessage({ message: "上传头像图片大小不能超过 2MB!", type: "error" });
            }
            return isJPG && isLt2M;
        };
        const handleAvatarSuccess = (response, uploadfile) => {
            
            // state.form.img = URL.createObjectURL(uploadfile.raw);
            state.pics = response.data;
            if(state.pics){
                ElMessage({ message: "图片上传成功", type: "success" });
                setTimeout(() => {
                    onSubmit();
                }, 500);
               
            }
            
        };
        const handleClick = (TabsPaneContext, Event) => {
            // console.log(TabsPaneContext['props']['name']);
            state.activeName = TabsPaneContext['props']['name'];
            Cvlist({type:state.activeName}).then((e)=>{
                state.tableData = e;
                state.tableData.forEach((item,index) => {

                    if(item.config_type == 4){  
                        state.arr = item.config_desc.split(',');
                    }
                    
                    
                });
                
            })
            
        };
        return {
            ...toRefs(state),
            onSubmit,
            beforeAvatarUpload,
            handleAvatarSuccess,
            tpid,
            handleClick,
            base
        };
    }
};
</script>
<style  >
.ys{
    margin-top: 1px;
}
.el-form-item__content{
    display: block !important;
    align-items:left !important;
}
</style>