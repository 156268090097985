<template>
    <Div>
        <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
        <div>
            <el-table :data="tableData"  :key="index" style="width: 100%;margin-bottom: 20px;" row-key="config_id" :header-cell-style="{background:'#f9f9f9'}" stripe default-expand-all
            v-loading="loading" >

                <el-table-column prop="config_id" label="ID" width="55"></el-table-column>
                <el-table-column prop="config_info" label="项目名" width="95"></el-table-column>
                <el-table-column label="所属" width="85">
                    <template #default="scope">
                        <el-link type="danger" v-if="scope.row.type == 1">后台配置</el-link>
                        <el-link type="success" v-if="scope.row.type == 2">前台配置</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="config_name" label="字段"></el-table-column>
                <el-table-column prop="config_desc" label="说明"></el-table-column>
                <el-table-column prop="config_sort" label="排序" width="80"></el-table-column>
                <el-table-column  label="操作">
                    <template #default="scope">
                        <el-button type="primary" size="mini" icon="Edit" @click="showDialog(2,scope.row)">修改</el-button>
                        <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 添加修改 -->
            <el-dialog v-model="dialog" :title="title">
                <el-form :model="form" label-width="120px" size="large">
                    <el-form-item label="项目名">
                        <el-input v-model="form.config_info" placeholder="请输入项目名" />
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="form.type" placeholder="请选择类型">
                            <el-option label="后台配置" :value="1" />
                            <el-option label="前台配置" :value="2" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="字段名">
                        <el-input v-model="form.config_name" placeholder="请输入字段名" />
                    </el-form-item>

                    <el-form-item label="说明">
                        <el-input v-model="form.config_desc" placeholder="请输入说明" />
                    </el-form-item>

                    <el-form-item label="排序" v-if="src_show">
                        <el-input v-model="form.config_sort" placeholder="请输入排序" />
                    </el-form-item>

                    

                    <el-form-item label="类型">
                        <el-select v-model="form.config_type" placeholder="请选择类型">
                            <el-option label="文本" :value="1" />
                            <el-option label="图片" :value="2" />
                            <el-option label="富文本" :value="3" />
                            <el-option label="单选框" :value="4" />
                        </el-select>
                    </el-form-item>

                
                    
                    
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button size="default" @click="dialog = false">取消</el-button>
                        <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </Div>
</template>
<script>
import { toRefs, reactive } from "vue";
import { Configlist, Configedit, Configdel } from "@/network/config";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    name: "Confs",
    setup() {
        
        const state = reactive({
            tableData:[],
            dialog : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            form: {
                config_id:0,
                config_name: "",
                config_info :"",
                config_type : 1,
                config_value: "",
                config_desc:"",
                config_sort:0,
                config_status: 1,
                type:1,

            },
        });
        Configlist().then((e)=>{
            // console.log(e,"88");
            state.tableData = e;
        } )
        const showDialog = (e,row)=>{
            state.dialog = true;
            if(e == 1){
                state.form = {
                    config_id:0,
                    config_name: "",
                    config_info :"",
                    config_type : 1,
                    config_value: "",
                    config_desc:"",
                    config_sort:0,
                    config_status: 1,
                    type:1,
                };
                state.title = "添加";
            }else{
                state.title = "修改";
                state.form = {
                    config_id: row.config_id,
                    config_name: row.config_name,
                    config_info: row.config_info,
                    config_type: row.config_type,
                    config_value: row.config_value,
                    config_desc: row.config_desc,
                    config_sort:row.config_sort,
                    type:row.type
                };
               
            }
        }
        const submitForm = ()=>{
            Configedit(state.form).then( (e)=>{
                if (e != 1) {
                    ElMessage({ message:'成功', type: "success" });
                    Configlist().then( (e)=>{
                        state.tableData = e;
                        state.dialog = false;
                    } )
                    
                }
            } )
        }
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.config_info, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                Configdel({config_id:row.config_id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        Configlist().then( (e)=>{
                          state.tableData = e;
                        } )
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }
        return {
            ...toRefs(state),
            showDialog,
            submitForm,
            delData
        };
    }
};
</script>